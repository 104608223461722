import { useEffect, useState } from "react";

const RandomEmoji = ({ index }) => {
  const [emoji, setEmoji] = useState("");

  useEffect(() => {
    index !== undefined
      ? setEmoji(emojis[index])
      : setEmoji(emojis[Math.floor(Math.random() * emojis.length)]);
    // setEmoji(emojis[Math.floor(Math.random() * emojis.length)]);
  }, []);

  const emojis = [
    "🚀",
    "🔥",
    "💣",
    "🕹",
    "💾",
    "🛸",
    "🧨",
    "🤟",
    "🤛",
    "🤜",
    "🤞",
    "🤘",
    "💡",
    "👾",
  ];
  return emoji;
};

export { RandomEmoji };
